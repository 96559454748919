import { Component, HostListener } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TableService } from './components/table/table.service';
import { AuthService } from './pages/login/auth.service';
import { MenuService } from './services/menu.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  isProd: boolean = environment.production;

  public appPages = [
    { title: 'Dashboard', url: '/dashboard', icon: 'dashboard' },
    { title: 'Change Requests', url: '/change-requests-history', icon: 'history'},
    { title: 'Client Management', url: '/client-management', icon: 'users' },
    { title: 'Admin Users', url: '/admin-users', icon: 'admin' },
    { title: 'Fund Information', url: '/fund-information', icon: 'fund' },
    { title: 'BI Analytics', url: '/bi-analytics', icon: 'analytics', excludedDepartments: ['Sales'] },
    { title: 'Admin Panel', url: '/admin-data', icon: 'user-management' },
    // { title: 'Documents', url: '/documents', icon: 'documents' },
  ];
  
  menuOpen: boolean = false;
  adminDepartment: string = '';

  constructor(
    private tableService: TableService,
    private authService: AuthService,
    private menuService: MenuService
  ) {}

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.authService.adminDepartment.subscribe(value => {
      this.adminDepartment = value;
      console.log(value)
    })
  }

  toggleMenu(state?: boolean) {
    console.log(state)
    if (state) {
      this.menuOpen = true;
    } else {
      this.menuOpen = !this.menuOpen;
      this.tableService.recalculate(true);
    };
    this.menuService.setMenuOpenStatus(this.menuOpen);
  }

  @HostListener('mousewheel', ['$event']) onMouseWheelChrome(event: any) {
    this.disableScroll(event);
  }

  @HostListener('DOMMouseScroll', ['$event']) onMouseWheelFirefox(event: any) {
      this.disableScroll(event);
  }

  @HostListener('onmousewheel', ['$event']) onMouseWheelIE(event: any) {
      this.disableScroll(event);
  }


  disableScroll(event: any) {
      if (event.srcElement.type === "number")
          event.preventDefault();
  }

}
