import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthGuard } from './guards/auth.guard';
import { AutoLoginGuard } from './guards/auto-login.guard';
import { LoggedInGuard } from './guards/logged-in.guard';
import { DepartmentGuard } from './guards/department.guard';

const routes: Routes = [
  environment.production ? {
    path: 'ui-elements',
    redirectTo: '/dashboard',
  } : {
    path: 'ui-elements',
    loadChildren: () => import('./pages/ui-elements/ui-elements.module').then( m => m.UiElementsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule),
    canLoad: [LoggedInGuard, AutoLoginGuard],
    title: 'Login'
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule),
    canLoad: [LoggedInGuard],
    title: 'Forgot Password'
  },
  {
    path: 'new-password/:token',
    loadChildren: () => import('./pages/new-password/new-password.module').then( m => m.NewPasswordPageModule),
    canLoad: [LoggedInGuard],
    title: 'New Password'
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule),
    canLoad: [AuthGuard],
    title: 'Dashboard'
  },
  {
    path: 'change-requests-history',
    loadChildren: () => import('./pages/change-requests-history/change-requests-history.module').then( m => m.ChangeRequestsHistoryPageModule),
    canLoad: [AuthGuard],
    title: 'Change Request History'
  },
  {
    path: 'client-management',
    loadChildren: () => import('./pages/user-management/user-management.module').then( m => m.UserManagementPageModule),
    canLoad: [AuthGuard],
    title: 'Client Management'
  },
  {
    path: 'admin-users',
    loadChildren: () => import('./pages/admin-users/admin-users.module').then( m => m.AdminUsersPageModule),
    canLoad: [AuthGuard],
    title: 'Admin Users'
  },
  {
    path: 'admin-data',
    loadChildren: () => import('./pages/admin-data/admin-data.module').then( m => m.AdminDataPageModule),
    canLoad: [AuthGuard],
    title: 'Admin Panel'
  },
  {
    path: 'fund-information',
    loadChildren: () => import('./pages/fund-information/fund-information.module').then( m => m.FundInformationPageModule),
    canLoad: [AuthGuard],
    title: 'Fund Information'
  },
  {
    path: 'bi-analytics',
    loadChildren: () => import('./pages/bi-analytics/bi-analytics.module').then( m => m.BiAnalyticsPageModule),
    canLoad: [AuthGuard],
    canActivate: [DepartmentGuard],
    data: { excludedDepartments: ['Sales'] },
    title: 'BI Analytics'
  },
  {
    path: 'documents',
    loadChildren: () => import('./pages/documents/documents.module').then( m => m.DocumentsPageModule),
    canLoad: [AuthGuard],
    title: 'Documents'
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then( m => m.ProfilePageModule),
    canLoad: [AuthGuard],
    title: 'Profile'
  },
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  },
  {
    path: 'version',
    loadChildren: () => import('./pages/version/version.module').then( m => m.VersionPageModule),
    title: 'Version page'
  },
  {
    path: 'testingpdf',
    loadChildren: () => import('./pages/testingpdf/testingpdf.module').then( m => m.TestingpdfPageModule)
  },
  {
    path: '**',
    redirectTo: '/dashboard',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
