import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class MenuService {

    constructor() {}

    // Get menu open status
    private menuOpenStatus = new BehaviorSubject(false);

    getMenuOpenStatus() {
        return this.menuOpenStatus.asObservable();
    }

    setMenuOpenStatus(status:boolean) {
        this.menuOpenStatus.next(status);
    }

}