import { Injectable } from '@angular/core';
import { ToastButton, ToastController } from '@ionic/angular';

export interface ToastOptions {
  message: string;
  status?: 'success' | 'error' |'warning';
  icon?: string;
  tryAgain?: boolean;
  tryAgainText?: string;
  additionalButtons?: ToastButton[];
  callback?: Function;
}

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private toastController: ToastController) { }

  async present(options: ToastOptions) {
    const buttons: ToastButton[] = [
      {
        role: 'cancel',
        icon: 'close',
        cssClass: 'toast-button--close'
      }
    ];
    
    if (options.additionalButtons) buttons.unshift(...options.additionalButtons);

    if (options.tryAgain) {
      buttons.unshift({
        text: options.tryAgainText ? options.tryAgainText : 'Try again',
        role: 'try-again',
        cssClass: 'toast-button'
      })
    }

    const toast = await this.toastController.create({
      message: options.message,
      position: 'top',
      duration: 5000,
      icon: options.icon || 'add-circle',
      cssClass: options.status,
      buttons
    });

    await toast.present();

    const { role } = await toast.onDidDismiss();
    console.log(`Dismissed with role: ${role}`);

    if (options.callback) options.callback(role);
  }
}
