import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { from, Observable, lastValueFrom, catchError, throwError, switchMap } from 'rxjs';
import { AuthService } from '../pages/login/auth.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { ToastService } from '../services/toast.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService, 
    private router: Router,
    private toastService: ToastService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Skip the interceptor for the refresh token endpoint
    if (request.url.includes('/auth/refresh')) {
      return next.handle(request);
    }

    return from(this.handleAccess(request, next)).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          // Attempt to refresh the token
          console.log('401 error, ATTEMPT TO REFRESH TOKEN')
          return from(this.authService.getRefreshToken()).pipe(
            switchMap((refreshToken) => {
              console.log('REFRESH TOKEN FROM STORAGE', refreshToken)
              if (refreshToken && refreshToken.value) {
                return this.authService.refreshToken(refreshToken.value).pipe(
                  switchMap((newTokens) => {
                    console.log('NEW TOKENS', newTokens)
                    if (newTokens.accessToken && newTokens.refreshToken) {
                      console.log('SETTING NEW TOKENS')
                      this.authService.setToken(newTokens.accessToken);
                      this.authService.setRefreshToken(newTokens.refreshToken);
                      request = request.clone({
                        setHeaders: {
                          Authorization: `Bearer ${newTokens.accessToken}`,
                        },
                      });
                      console.log('CLONING ORIGINAL REQUEST')
                      return next.handle(request);
                    } else {
                      console.log('NEW TOKENS NOT SET, LOGOUT')
                      this.authService.logout(false);
                      this.router.navigateByUrl('/login', { replaceUrl: true });
                      return throwError(() => error);
                    }
                  })
                )
              } else {
                console.log('REFRESH TOKEN NOT FOUND IN STORAGE, LOGOUT')
                this.authService.logout(false);
                this.router.navigateByUrl('/login', { replaceUrl: true });
                return throwError(() => error);
              }
            })
          )
        }
        return throwError(() => error);
      }),
      catchError((tokenError) => {
        console.log('ERROR STATUS', tokenError.status)
        console.log('ENTIRE ERROR', tokenError)
        if (tokenError.status === 401) {
          console.log('ERROR FETCHING REFRESH TOKEN, LOGOUT')
          this.authService.logout(false);
          this.router.navigateByUrl('/login', { replaceUrl: true });
        }
        if (tokenError.status === 500) {
          this.showToast('error', tokenError.error.message);
        }
        return throwError(() => tokenError);
      })
    );
  }

  private async handleAccess(request: HttpRequest<any>, next: HttpHandler):Promise<HttpEvent<any>> {
    const token = await this.authService.getToken();
    const isApiUrl = request.url.startsWith(environment.apiURL);

    if (token.value && isApiUrl) {
        request = request.clone({
            setHeaders: { 
                Authorization: `Bearer ${token.value}`,
                'x-platform': environment.platform
            }
        });
    } else if(isApiUrl) {
      request = request.clone({
        setHeaders: {
            'x-platform': environment.platform
        }
      });
    }

    return lastValueFrom(next.handle(request));
  }

  private showToast(status: 'success' | 'error' | 'warning' = 'success', message: string) {
    this.toastService.present(
      {
        message: message,
        status,
        tryAgain: false,
      }
    )
  }
}